<template>
  <transition name="slide">
    <router-view />
  </transition>
</template>

<script>
export default {
  name: "Settings",
};
</script>
